@media screen and (min-width: 1366px) {
    .section404 {
        display: flex;
        flex-direction: column;
        padding-top: 15vh;
        padding-bottom: 15vh;
    }

    .returnButton {
        margin: 15vh;
        font-size: 1vw;
        text-align: left;
    }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
    .section404 {
        display: flex;
        flex-direction: column;
        padding-top: 15vh;
        padding-bottom: 15vh;
    }

    .returnButton {
        margin: 15vh;
        font-size: 1.2vw;
        text-align: left;
    }
}

@media screen and (max-width: 767px) {
    .section404 {
        display: flex;
        flex-direction: column;
        padding-top: 15vh;
        padding-bottom: 15vh;
    }

    .returnButton {
        margin: 15vh;
        font-size: 3.5vw;
        text-align: left;
    }
}
