.name,
.subtitle,
.skills,
.employment,
.subheading,
.contactText {
  font-family: "Anonymous Pro";
}

.name:hover {
  text-decoration: none !important;
}

.nameButton {
  border-width: 0 !important;
  background-color: transparent;
}

.skills {
  color: #808080;
}

@media screen and (min-width: 1366px) {
  .sectionProfile {
    display: flex;
    flex-direction: column;
    padding-top: 15vw;
    padding-bottom: 10vw;
  }

  .sectionSkills {
    display: flex;
    flex-direction: column;
    padding-bottom: 10vw;
    text-align: left;
    font-size: 1.2vw;
    color: #303030;
  }

  .employment {
    display: flex;
    flex-direction: column;
    padding-bottom: 10vw;
    text-align: left;
    font-size: 1.2vw;
    color: #303030;
  }

  .sectionContact {
    display: flex;
    flex-direction: column;
    padding-bottom: 10vw;
    text-align: left;
    font-size: 1.2vw;
    color: #303030;
  }

  .name {
    font-size: 2.5vw;
    text-align: left;
    color: #303030;
  }

  .subheading {
    font-size: 1.5vw;
    text-align: left;
    color: #303030;
  }

  .subtitle {
    font-size: 1.2vw;
    text-align: left;
    color: #808080;
  }

  .location {
    margin-top: 1.2vw;
    font-size: 1.2vw;
    text-align: left;
    color: #808080;
  }

  .contactLink {
    margin-bottom: 2vw;
    width: 50vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .sectionProfile {
    display: flex;
    flex-direction: column;
    padding-top: 15vw;
    padding-bottom: 10vw;
  }

  .sectionSkills {
    display: flex;
    flex-direction: column;
    padding-bottom: 10vw;
    min-height: 50vw;
    text-align: left;
    font-size: 2vw;
    color: #303030;
  }

  .employment {
    display: flex;
    flex-direction: column;
    padding-bottom: 10vw;
    min-height: 50vw;
    text-align: left;
    font-size: 2vw;
    color: #303030;
  }

  .sectionContact {
    display: flex;
    flex-direction: column;
    padding-bottom: 10vw;
    min-height: 50vw;
    text-align: left;
    font-size: 2vw;
    color: #303030;
  }

  .name {
    font-size: 5vw;
    text-align: left;
    color: #303030;
  }

  .subheading {
    font-size: 4vw;
    text-align: left;
    color: #303030;
  }

  .subtitle {
    font-size: 2vw;
    text-align: left;
    color: #808080;
  }

  .location {
    margin-top: 4vw;
    font-size: 2vw;
    text-align: left;
    color: #808080;
  }

  .contactLink {
    margin-bottom: 4vw;
    width: 50vw;
  }
}

@media screen and (max-width: 767px) {
  .sectionProfile {
    display: flex;
    flex-direction: column;
    padding-top: 15vw;
    padding-bottom: 10vw;
  }

  .sectionSkills {
    display: flex;
    flex-direction: column;
    padding-bottom: 10vw;
    text-align: left;
    font-size: 3.5vw;
    color: #303030;
  }

  .employment {
    display: flex;
    flex-direction: column;
    padding-bottom: 10vw;
    text-align: left;
    font-size: 3.5vw;
    color: #303030;
  }

  .sectionContact {
    display: flex;
    flex-direction: column;
    padding-bottom: 10vw;
    text-align: left;
    font-size: 3.5vw;
    color: #303030;
  }

  .name {
    font-size: 7vw;
    text-align: left;
    color: #303030;
  }

  .subheading {
    font-size: 5vw;
    text-align: left;
    color: #303030;
  }

  .subtitle {
    font-size: 3.5vw;
    text-align: left;
    color: #808080;
  }

  .location {
    margin-top: 1.2vw;
    font-size: 4vw;
    text-align: left;
    color: #808080;
  }

  .contactLink {
    margin-bottom: 5vw;
    width: 50vw;
  }
}
