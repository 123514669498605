* {
  font-family: "ndot55", "Anonymous Pro", monospace;
  color: #1d1d1d;
  scrollbar-width: none;
  z-index: 1;
}

/* br {
  display: block;
  margin: 1em 0;
} */

::-webkit-scrollbar {
  display: none;
}

html,
body {
  width: 100vw !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: #f2f2f2 !important;
  scrollbar-width: none !important;
}

a {
  color: #1d1d1d !important;
  text-decoration: none !important;
}

a,
button,
.navbar,
.footer,
.name,
.subFooter,
.digitalBath,
.subheading {
  z-index: 2 !important;
}

a:hover,
.backToTop:hover,
.lightsButton:hover {
  text-decoration: line-through !important;
}

.backToTop {
  border-width: 0;
  background-color: transparent;
}

.container,
.container-fluid,
.row,
.col {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.timeDisplay,
.backToTop {
  color: #1d1d1d;
  text-decoration: none !important;
}

@media screen and (min-width: 1366px) {
  .footer {
    width: 100vw;
    min-height: 5vh;
    padding-bottom: 1.2vw;
    align-items: center;
    justify-content: center;
  }

  .digitalBath {
    margin-top: 1vw;
    font-size: 1.2vw;
    text-align: left;
  }

  .year {
    margin-top: 1vw;
    font-size: 1.2vw;
    text-align: center;
  }

  .abn {
    margin-top: 1vw;
    font-size: 1.2vw;
    text-align: right;
  }

  .footerDot {
    margin-top: 1vw;
    font-size: 1.5vw;
    text-align: center;
  }

  .comingSoon {
    font-size: 2vw;
    text-align: center;
  }

  .backToTop {
    position: fixed;
    bottom: 1.2vw;
    right: 1.2vw;
    font-size: 1.2vw;
  }

  .timeDisplay {
    position: fixed;
    bottom: 1.2vw;
    left: 1.2vw;
    font-size: 1.2vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .footer {
    width: 100%;
    min-height: 5vh;
    padding-bottom: 1.2vw;
    align-items: center;
    justify-content: center;
  }

  .digitalBath {
    margin-top: 1vw;
    font-size: 2vw;
    text-align: center;
  }

  .year {
    margin-top: 1vw;
    font-size: 2vw;
    text-align: center;
  }

  .abn {
    margin-top: 1vw;
    font-size: 2vw;
    text-align: center;
  }

  .footerDot {
    margin-top: 1vw;
    font-size: 4vw;
    text-align: center;
  }

  .comingSoon {
    font-size: 5vw;
    text-align: center;
  }

  .backToTop {
    position: fixed;
    bottom: 1.2vw;
    right: 5vw;
    font-size: 2vw;
  }

  .timeDisplay {
    position: fixed;
    bottom: 1.2vw;
    left: 5vw;
    font-size: 2vw;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    width: 100%;
    min-height: 5vh;
    padding-bottom: 5vw;
    align-items: center;
    justify-content: center;
  }

  .digitalBath,
  .year {
    margin-top: 5vw !important;
    font-size: 3.5vw;
    text-align: center;
  }

  .abn {
    margin-top: 5vw !important;
    font-size: 3.5vw;
    text-align: center;
  }

  .comingSoon {
    font-size: 7vw;
    text-align: center;
  }

  .backToTop {
    position: fixed;
    bottom: 5vw;
    right: 5vw;
    font-size: 3.5vw;
  }

  .timeDisplay {
    position: fixed;
    bottom: 5vw;
    left: 5vw;
    font-size: 3.5vw;
  }
}
